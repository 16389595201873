import useSWR from 'swr'
import { findNowMetricsByCommuneSlug } from '../services/MetricTypeServices'
import { NowMetrics } from '../ts/types/metric.types'
import useCommune from './useCommune'

type ReturnProps = {
  data?: NowMetrics
  error: unknown
  loading: boolean
}

const useMetricsNow = (): ReturnProps => {
  const { slug } = useCommune()
  const { data, error } = useSWR([slug, '/metrics/{communeSlug}/now'], findNowMetricsByCommuneSlug)

  const loading = !data && !error

  return { data: data, error, loading }
}

export default useMetricsNow
