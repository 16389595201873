import { NextPage } from 'next'
import { Panel } from 'rsuite'
import numbro from 'numbro'
import dynamic from 'next/dynamic'
import DashboardLayout from '../../components/shared/dashboard/DashboardLayout'
import Container from '../../components/shared/Container'
import CustomText from '../../components/base/CustomText'
import PageHeader from '../../components/shared/PageHeader'
import useCommune from '../../hooks/useCommune'
import useMetricsNow from '../../hooks/useMetricsNow'
import LoadingWrapper from '../../components/shared/LoadingWrapper'
import useMetricsAsset from '../../hooks/useMetricsAsset'

const FailureEventsChart = dynamic(() => import('../../components/pages/metrics/FailureEventsChart'), { ssr: false })

const IndexPage: NextPage = () => {
  const { name } = useCommune()
  const { data: dataNow, loading: loadingNow } = useMetricsNow()
  const { data: dataAsset, loading: loadingAsset } = useMetricsAsset()

  const assetsCount = dataAsset?.reduce((acc, cur) => acc + cur.count, 0) || 0

  return (
    <DashboardLayout>
      <Container className="py-6 space-y-4">
        <PageHeader title={`Métricas de ${name}`} />
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-6">
          <Panel bordered className="bg-white">
            <LoadingWrapper loading={loadingNow || loadingAsset}>
              <div className="flex flex-col items-center justify-center space-y-2">
                <CustomText size="heading-3" weight={600}>
                  {numbro(assetsCount).format({ thousandSeparated: true })}
                </CustomText>

                <CustomText size="caption" weight={400} className="text-gray-darker text-center">
                  Activos
                </CustomText>
              </div>
            </LoadingWrapper>
          </Panel>
          <Panel bordered className="bg-white">
            <LoadingWrapper loading={loadingNow}>
              <div className="flex flex-col items-center justify-center space-y-2">
                <CustomText size="heading-3" weight={600}>
                  {numbro(dataNow?.not_repaired_failures || 0).format({ thousandSeparated: true })}
                </CustomText>

                <CustomText size="caption" weight={400} className="text-gray-darker text-center">
                  Fallas No Reparadas
                </CustomText>
              </div>
            </LoadingWrapper>
          </Panel>
          <Panel bordered className="bg-white">
            <LoadingWrapper loading={loadingNow}>
              <div className="flex flex-col items-center justify-center space-y-2">
                <CustomText size="heading-3" weight={600}>
                  {numbro(dataNow?.pre_repaired_failures || 0).format({ thousandSeparated: true })}
                </CustomText>

                <CustomText size="caption" weight={400} className="text-gray-darker text-center">
                  Fallas Pre-reparadas
                </CustomText>
              </div>
            </LoadingWrapper>
          </Panel>
          <Panel bordered className="bg-white">
            <LoadingWrapper loading={loadingNow}>
              <div className="flex flex-col items-center justify-center space-y-2">
                <CustomText size="heading-3" weight={600}>
                  {numbro(dataNow?.with_incident_assets || 0).format({ thousandSeparated: true })}
                </CustomText>

                <CustomText size="caption" weight={400} className="text-gray-darker text-center">
                  Activos con Incidencia
                </CustomText>
              </div>
            </LoadingWrapper>
          </Panel>
        </div>

        <FailureEventsChart />
      </Container>
    </DashboardLayout>
  )
}

export default IndexPage
