import { ReactNode } from 'react'
import { IconButton } from 'rsuite'
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine'
import clsx from 'clsx'
import CustomText from '../base/CustomText'
import CustomLink from './CustomLink'

type Props = {
  children?: ReactNode
  title: string | ReactNode
  subTitle?: string | ReactNode
  backHref?: string
  hideLeftContentOnMobile?: boolean
}

const PageHeader = ({ children, title, subTitle, backHref, hideLeftContentOnMobile }: Props): JSX.Element => {
  return (
    <div className="flex items-center justify-between">
      <div className={clsx(hideLeftContentOnMobile ? 'hidden lg:flex' : '', 'flex items-center')}>
        {backHref ? (
          <div className="hidden lg:block mr-2">
            <IconButton as={CustomLink} href={backHref} size="sm" appearance="primary" icon={<ArrowLeftLineIcon />} />
          </div>
        ) : null}
        <div className="mr-2">
          <CustomText size="heading-4" weight={400} className="text-gray-darker">
            {title}
          </CustomText>
          {subTitle ? (
            <CustomText size="subtitle-2" weight={400} className="text-gray-dark">
              {subTitle}
            </CustomText>
          ) : null}
        </div>
      </div>
      {children ? <div className={hideLeftContentOnMobile ? 'flex-1 lg:flex-none' : ''}>{children}</div> : null}
    </div>
  )
}

export default PageHeader
