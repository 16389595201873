import React, { ReactNode } from 'react'
import { Loader } from 'rsuite'

type Props = {
  children?: ReactNode
  loading?: boolean
}

const LoadingWrapper = ({ children, loading }: Props): JSX.Element => {
  return (
    <div className="relative">
      {children}

      {loading ? <Loader backdrop /> : null}
    </div>
  )
}

export default LoadingWrapper
