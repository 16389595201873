import useSWR from 'swr'
import { findAssetMetricsByCommuneSlug } from '../services/MetricTypeServices'
import { AssetMetric } from '../ts/types/metric.types'
import useCommune from './useCommune'

type ReturnProps = {
  data?: AssetMetric[]
  error: unknown
  loading: boolean
}

const useMetricsAsset = (): ReturnProps => {
  const { slug } = useCommune()
  const { data, error } = useSWR([slug, '/metrics/{communeSlug}/assets'], findAssetMetricsByCommuneSlug)

  const loading = !data && !error

  return { data: data, error, loading }
}

export default useMetricsAsset
