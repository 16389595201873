import axios from '../utils/services'
import { AssetMetric, FailureEventMetrics, NowMetrics } from '../ts/types/metric.types'

export const findAssetMetricsByCommuneSlug = (communeSlug: string): Promise<AssetMetric[]> => {
  return axios.get(`/metrics/${communeSlug}/assets/`)
}

export const findNowMetricsByCommuneSlug = (communeSlug: string): Promise<NowMetrics> => {
  return axios.get(`/metrics/${communeSlug}/now/`)
}

export const findFailureEventsMetricsByCommuneSlug = async (
  communeSlug: string,
  startsAt: string,
  endsAt: string
): Promise<FailureEventMetrics> => {
  const dateRangeParam = `?starts_at=${startsAt}&ends_at=${endsAt}`
  return axios.get(`/metrics/${communeSlug}/events/${dateRangeParam}`)
}
