import clsx from 'clsx'

import { ReactNode } from 'react'

type Props = {
  children?: ReactNode
  maxWidth?: number
  style?: object
  className?: string
  horizontalSpacingClassName?: string
}

const Container = ({ children, maxWidth, style, className, horizontalSpacingClassName }: Props): JSX.Element => {
  return (
    <div
      className={clsx(
        'block w-full mx-auto box-border relative',
        className,
        horizontalSpacingClassName || 'px-3 md:px-6'
      )}
      style={{ maxWidth: maxWidth, ...style }}
    >
      {children}
    </div>
  )
}

Container.defaultProps = {
  maxWidth: 1450,
}

export default Container
